.recommend_product_view{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    li{
        width: 33.333%;
        padding: 8px;
        .item_wrap{
            width: 100%;
            border: 1px solid #f5f5f5;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            -ms-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
        }
    }
}

.recommend_product_view{
    text-align: center;
    margin: 15px 0;
}
.recommend_product_view li{
    margin: 15px 0;
    .item_wrap{
        display: block;
        width: 100%;
    }
}
.recommend_product_view li .item_wrap a{
    color: #333;
}
.recommend_product_view li .item_wrap a p{
    display: block;
    padding: 5px 0 10px 0;
}
.recommend_product_view li .item_wrap a:hover p{
    color: #018ae2;
}
.ov-he{
    height: 240px!important;
}
.recommend_product_view li:hover .item_wrap{
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
    transform: scale(1.01);
    -webkit-box-shadow: 6px 6px 4px 6px #f0f0f0;
    -moz-box-shadow: 6px 6px 4px 6px #f0f0f0;
    box-shadow: 6px 6px 4px 6px #f0f0f0;
}
.not_match_page{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem 0;
    width: 50%;
    min-width: 1080px;
    margin: 0 auto;
    .msg{
        font-size: 28px;
        display: block;
        margin-bottom: 30px;
    }
    .rec_section{
        width: 100%;
        &>b{
            display: block;
            font-size: 18px;
            margin-bottom: 15px;
        }
    }
}