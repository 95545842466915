.header_carousel_img{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 650px;
}
.head_right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}