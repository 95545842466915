@charset "UTF-8";
header,footer{
    display: block;
    width: 100%;
    position: relative;
    background: #fff;
}
.header{
    width: 1200px;
    margin: 0 auto;
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.head_left *{
    display: inline-block;
    vertical-align: middle;
}
.head_left>img{
    width: 100px;
}
.head_left>span{
    padding-left: 10px;
}
.head_left>span>b{
    font-size: 18px;
}
.head_left>span>i{
    display: block;
    font-style: normal;
}
.head_nav{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.head_nav li{
    display: block;
}
.head_nav li a{
    display: block;
    padding: 15px;
    color: #333;
}
.head_nav li a:hover{
    color: #018ae2;
}
.lbimg{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
}
.lbimg>img{
    width: 100%;
}
.main{
    margin: 0px auto;
    padding: 40px 0 10px 0;
    background: #fff;
}
.histroy,.other,.index_pro,.index_news{
    margin: 40px auto;
    width: 1200px;
    padding: 40px 0;
}
.histroy_ul{
    width: 100%;
}
.histroy_ul li{
    float: left;
    width: 33.33%;
    text-align: center;
}
.histroy_ul li h2{
    font-size: 25px;
}
.histroy_ul>li>img{
    width: 128px;
}
.odo1,.odo2,.odo3{
    width: 100%;
    font-size: 60px;
    font-family: Roboto;
    fill: #018ae2;
    margin: 20px auto 10px auto;
}
.counter{
    width: 100%;
    font-size: 60px;
    font-family: Roboto;
    color: #018ae2;
    margin: 20px auto 10px auto;
}
.counter .sub{
    font-size: 14px;
    bottom: 2px;
    color: #777;
}
.other_box{
    text-align: center;
}
.other_box>h1{
    font-size: 35px;
    margin: 20px auto 40px auto;
}
.other_ul li{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    height: 466px;
}
.other_ul li img{
    width: 60%;
    height: 466px;
}
.other_left{
    position: relative;
    background: #018ae2;
    width: 40%;
}
.index_pro_box,.index_news_box{
    text-align: center;
}
.index_pro_box h1,.index_news_box>h1{
    font-size: 35px;
}
.index_pro_box>p,.index_news_box>p{
    display: inline-block;
    font-size: 18px;
    color: #8D8D8D;
    margin: 20px auto;
}
.pro_type_link{
    margin: 20px auto;
}
.pro_type_link>span{
    display: inline-block;
    vertical-align: middle;
    padding: 8px 15px;
    font-size: inherit;
    color: #333;
    margin: 0 10px;
}
.pro_type_link>span:hover{
    background: #018ae2;
    color: #fff;
}
.js-bgcolor{
    background: #018ae2!important;
    color: #fff!important;
}
.index_pro_ul{
    display: -webkit-flex;
    display: flex;
    /*-webkit-justify-content: space-between;*/
    /*justify-content: space-between;*/
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
.index_pro_ul>li{
    width: 25%;
    margin: 15px 0;
    padding: 0 1%;
    overflow: hidden;
}
.index_pro_ul>li a{
    display: block;
    border: 1px solid #f1f1f1;
}
.index_pro_ul>li a:hover{
    border: 1px solid #d1d1d1;
}
.index_pro_ul>li>a>span{
    display: block;
    height: 288px;
    margin-bottom: 15px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.index_pro_ul>li>a>p{
    font-size: 18px;
    margin: 10px auto;
    color: #333;
    display: block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    padding: 0 10px;
}
.index_pro_ul>li>a:hover>p{
    color: #018ae2;
}
.index_pro_ul>li>a:hover>span{
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
.index_news_ul{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 30px auto;
}
.index_news_ul>li{
    width: 45%;
    padding: 10px 10px 15px 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    margin: 15px 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.index_news_ul>li:hover{
    -webkit-box-shadow: 6px 6px 4px 6px #e0e0e0;
    -moz-box-shadow: 6px 6px 4px 6px #e0e0e0;
    box-shadow: 6px 6px 4px 6px #e0e0e0;
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
    transform: scale(1.01);
}
.index_news_ul>li>span{
    display: inline-block;
    width: 160px;
    height: 109px;
    margin-right: 20px;
}
.index_news_right{
    width: 66%;
    height: 109px;
}
.index_news_right>a>h1{
    font-size: 18px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
}
.index_news_right>a:hover>h1{
    color: #018ae2;
}
.index_news_right>span{
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: left;
    margin: 15px 0;
    color: #848484;
}
.index_news_time{
    text-align: left;
}
.index_news_time *{
    display: inline-block;
    vertical-align: middle;
    color: #585858;
}
.index_news_time i,.index_news_time em{
    font-style: normal;
    margin-right: 25px;
}
.index_news_time img{
    width: 18px;
    margin: 0 7px 0 30px;
}
.read_more{
    display: block;
    margin: 0 auto;
    width: 150px;
    padding: 0 5px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    color: #000000;
    border: 1px solid #000000;
    margin-top: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background-color 0.5s linear;
    -moz-transition: background-color 0.5s linear;
    -webkit-transition: background-color 0.5s linear;
    -o-transition: background-color 0.5s linear;
}
.read_more:hover{
    color: #fff;
    background-color: #018ae2;
    border-color: #018ae2;
}
footer{
    background: #2F86A6;
}
.foot{
    padding: 30px 0;
    width: 1200px;
    margin: 0 auto;
    color: #fff;
}
.foot_nav{
    /*width: 1000px;*/
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: flex-start;
}
.nav_inline{
    width: 20%;
    padding-left: 95px;
}
.nav_inline>h2{
    display: block;
    margin: 15px 0;
}
.foot_ul{
    margin-left: 15px;
}
.foot_ul li a{
    display: block;
    padding: 10px 0;
    color: #fff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.foot_ul li a:hover{
    color: #018ae2;
    text-decoration: underline;
}
.ba{
    text-align: center;
    margin: 30px auto;
}
.silder{
    position: fixed;
    top: 30%;
    right: 0;
    z-index: 10000;
}
.silder li{
    margin: 6px 0;
}
.silder li a{
    display: block;
    padding: 10px;
    border: 1px solid #f1f1f1;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background: rgba(255,255,255,.3);
}
.silder li a span{
    font-size: 32px;
    -webkit-transition: background-color -3s,color .3s;
    -moz-transition: background-color -3s,color .3s;
    -ms-transition: background-color -3s,color .3s;
    -o-transition: background-color -3s,color .3s;
    transition: background-color -3s,color .3s;
}
.silder li a img{
    width: 35px;
}
.silder li a:hover{
    background: #018ae2;
}
.t1{
    color: #2da61a;
}
.t2{
    color: #018ae2;
}
.t3{
    color: #018ae2;
}
.t4{
    color: #595959;
}
.silder li a:hover .t1,.silder li a:hover .t2,.silder li a:hover .t3,.silder li a:hover .t4{
    color: #fff;
}
.silder li a:hover .t1{
    background-color: #2da61a;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
.silder li a:hover .t2{
    background-color: #018ae2;
}
.silder li a:hover .t3{
    background-color: #018ae2;
    background-image: url("../img/e1.png");
}
.email{
    display: block;
    width: 33px;
    height: 32px;
    background: url("../img/email.png") no-repeat center;
    background-size: 100%;
}
.left_right{
    width: 1200px;
    margin: 20px auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 540px;
    padding-bottom: 50px;
}
.left_box{
    width: 20%;
    margin-right: 20px;
}
.right_box{
    width: 75%;
}
.left_tit{
    display: block;
    width: 100%;
    border-bottom: 1px solid #ebebeb;
}
.left_tit i{
    display: block;
    /*width: 40%;*/
    padding: 10px 0;
    font-style: normal;
    font-size: 18px;
    border-bottom: 2px solid #018ae2;
}
.link{
    padding: 15px 0;
}
.link a{
    display: block;
    width: 100%;
    margin: 4px 0;
    background: #f1f1f1;
    font-size: 16px;
    padding: 10px;
    color: #333;
}
.list{
    padding: 10px 0;
}
.list li{
    margin: 20px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.list li span{
    width: 75px;
}
.list li em{
    flex: 1;
    font-style: normal;
    color: #555555;
}
.right_box_content{
    margin: 15px 0;
    line-height: 30px;
    font-weight: 300;
}
.right_box_content>img{
    width: 100%;
    margin: 15px 0 30px 0;
}
.layui-breadcrumb{
    border-bottom: 1px solid #b0b0b0;
    padding-bottom: 15px;
    width: 100%;
    display: block;
}
.layui-breadcrumb>a>i{
    margin-right: 5px;
}
.layui-breadcrumb a:hover{
    color: #018ae2!important;
}
.news_ul{
    margin: 15px 0;
}
.news_ul li{
    border-bottom: 1px solid #f1f1f1;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 20px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.news_ul li:hover{
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
    transform: scale(1.01);
    -webkit-box-shadow: 6px 6px 4px 6px #e0e0e0;
    -moz-box-shadow: 6px 6px 4px 6px #e0e0e0;
    box-shadow: 6px 6px 4px 6px #e0e0e0;
}
.news_ul li a{
    font-size: 18px;
    font-weight: 700;
    display: block;
    color: #333;
}
.news_ul li a:hover{
    color: #018ae2;
}
.news_cont1{
    display: block;
    margin: 20px 0;
    line-height: 26px;
    font-weight: 300;
}
.news_content_tit{
    padding: 15px 0;
    border-bottom: 1px solid #d7d7d7;
}
.news_content_tit h1{
    margin-bottom: 10px;
}
.news_text{
    line-height: 24px;
    font-weight: 300;
}
.news_text img{
    width: 100%;
    margin: 20px 0;
}
.page_up_down{
    padding: 20px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    border-top: 1px solid #9F9F9F;
    margin: 20px 0;
}
.page_up_down a{
    display: block;
    width: 35%;
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    border: 1px solid #9F9F9F;
    padding: 8px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    color: #333;
}
.page_up_down a:hover{
    color: #018ae2;
    border-color: #018ae2;
}
.product_ul{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #c5c2c2;
}
.product_ul li{
    width: 30%;
    margin: 13px;
    text-align: center;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.product_ul li a{
    display: block;
}
.index_pro_imgl{
    display: block;
    width: 100%;
    height: 270px;
}
.product_ul li a p{
    margin: 8px 0;
}
.product_ul li a:hover p{
    color: #018ae2;
}
.product_ul li:hover{
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
    transform: scale(1.01);
    -webkit-box-shadow: 6px 6px 4px 6px #f0f0f0;
    -moz-box-shadow: 6px 6px 4px 6px #f0f0f0;
    box-shadow: 6px 6px 4px 6px #f0f0f0;
}
#pages{
    margin: 15px 0;
}
.tj_pro{
    text-align: center;
    margin: 15px 0;
}
.tj_pro li{
    margin: 15px 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.tj_pro li a p{
    display: block;
    padding: 5px 0 10px 0;
}
.tj_pro li a:hover p{
    color: #018ae2;
}
.ov-he{
    height: 240px!important;
}
.tj_pro li:hover{
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
    transform: scale(1.01);
    -webkit-box-shadow: 6px 6px 4px 6px #f0f0f0;
    -moz-box-shadow: 6px 6px 4px 6px #f0f0f0;
    box-shadow: 6px 6px 4px 6px #f0f0f0;
}
.product_text img{
    width: 60%;
    margin: 20px auto;
    display: block;
}
.product_text{
    line-height: 26px;
    font-weight: 300;
}
.cus{
    width: 1200px;
    margin: 20px auto;
    padding-bottom: 40px;
}
.contact{
    margin: 30px auto;
    display: block;
    /*width: 100%;*/
}
.contact li{
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
}
#index-cur div div a{
    display: block;
    width: 100%;
    height: 400px;
}
.baiduMap{
    display: block;
    width: 100%;
    height: 500px;
    background-color: #1E9FFF;
}
.BMap_bubble_title{
    color: #FD482C;
    font-size: 16px;
}
.BMap_bubble_content{
    font-size: 12px;
    color: #8D8D8D;
}
.layui-elem-field legend{
    font-size: 30px;
}
.join_box{
    width: 1200px;
    margin: 30px auto;
    padding: 10px;
}
.join_nav{
    display: block;
    margin: 20px auto;
    text-align: center;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
}
.join_nav_box{
    display: none;
}
.join_nav li{
    display: inline-block;
    vertical-align: middle;
    margin: 0 15px;
}
.join_nav li a{
    display: block;
    font-size: 18px;
}
.join_nav li a:hover{
    color: #018ae2;
}
.join_nav_box,.recruit{
    margin: 30px auto;
}
.recruit_top{
    padding: 10px 0;
    border-bottom: 1px solid #f1f1f1;
}
.recruit_top h1{
    padding-bottom: 10px;
}
.recruit_oth{
    display: block;
}
.recruit_oth i{
    font-style: normal;
    font-weight: 300;
    display: inline-block;
    margin: 0 10px;
}
.recruit_bottom{
    padding: 10px 0 10px 30px;
    border-bottom: 1px solid #f1f1f1;
}
.recruit_bottom ul{
    margin: 20px 0;
}
.recruit_bottom ul li{
    margin: 10px;
    font-weight: 300;
}
.recruit>a{
    margin: 15px 0;
}
.js-color{
    color: #018ae2!important;
}
.js-cont-show{
    display: block!important;
}
.layui-tab-brief>.layui-tab-more li.layui-this:after, .layui-tab-brief>.layui-tab-title .layui-this:after{
    border-bottom: 2px solid #2196F3!important;
}
.layui-tab-title li a{
    font-size: 18px;
}
.fk{
    padding: 20px 0;
    margin: 20px auto;
}
#jl-img{
    width: 150px;
}
#join_md{
    display: none;
    padding: 0 20px;
}
.cs li{
    display: inline-block;
    vertical-align: middle;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
.cs li a{
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
.cs li a span{
    display: block;
    width: 32px;
    height: 32px;
    font-size: 32px;
    -webkit-transition: background-color -3s,color .3s;
    -moz-transition: background-color -3s,color .3s;
    -ms-transition: background-color -3s,color .3s;
    -o-transition: background-color -3s,color .3s;
    transition: background-color -3s,color .3s;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
.cs li a img{
    width: 35px;
}
.cs li a:hover{
    background: #018ae2;
}
.t1{
    color: #2da61a;
}
.t2{
    color: #018ae2;
}
.t3{
    color: #018ae2;
}
.t4{
    color: #595959;
}
.cs li a:hover .t1,.cs li a:hover .t2,.cs li a:hover .t3,.cs li a:hover .t4{
    color: #fff;
}
.cs li a:hover .t1{
    background-color: #2da61a;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
.cs li a:hover .t2{
    background-color: #018ae2;
}
.cs li a:hover .t3{
    background-color: #018ae2;
    background-image: url("../img/e1.png");
}
.email{
    display: block;
    width: 32px;
    height: 32px;
    background: url("../img/email.png") no-repeat center;
    background-size: 100%;
}
.layui-form-select dl dd.layui-this{
    background-color: #018ae2;
}
.layui-form-radio>i:hover, .layui-form-radioed>i{
    color: #018ae2;
}
.layui-form-checked span, .layui-form-checked:hover span{
    background-color: #018ae2;
}
.layui-upload-drag .layui-icon{
    color: #018ae2;
}

.crumb_container{
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 8px;
}
.jsColor{
    background-color: #018ae2!important;
    color: #fff!important;
}